import { createEnv } from '@t3-oss/env-nextjs'
import * as z from 'zod'
import { looseHrefSchema } from '@purposity/utils'

export const env = createEnv({
  server: {
    NODE_ENV: z.enum(['development', 'test', 'production']),
    DEBUG: z.boolean().optional(),
    ADMIN_API_SECRET: z.string(),
    API_ORIGIN: looseHrefSchema,
    CLERK_SECRET_KEY: z.string(),
    CLERK_JWT_KEY: z.string(),
    EDGE_CONFIG: z.string(),
    PURPOSITY_CANARY_DEPLOYMENT: z.string().optional(),
    VERCEL_ENV: z.enum(['development', 'preview', 'production']).optional(),
  },
  client: {
    NEXT_PUBLIC_API_ORIGIN: looseHrefSchema.transform((v) => new URL(v)),
    NEXT_PUBLIC_CDN_ORIGIN: z.string(),
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: z.string(),
    NEXT_PUBLIC_CLERK_SIGN_IN_URL: z.string(),
    NEXT_PUBLIC_CLERK_SIGN_UP_URL: z.string(),
    NEXT_PUBLIC_CLERK_AFTER_SIGN_IN_URL: z.string(),
    NEXT_PUBLIC_CLERK_AFTER_SIGN_UP_URL: z.string(),
    NEXT_PUBLIC_PURPOSITY_CANARY_DEPLOYMENT: z.string().optional(),
    NEXT_PUBLIC_VERCEL_ENV: z.enum(['development', 'preview', 'production']).optional(),
  },
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    DEBUG: process.env.DEBUG,
    ADMIN_API_SECRET: process.env.ADMIN_API_SECRET,
    API_ORIGIN: process.env.API_ORIGIN,
    CLERK_SECRET_KEY: process.env.CLERK_SECRET_KEY,
    CLERK_JWT_KEY: process.env.CLERK_JWT_KEY,
    EDGE_CONFIG: process.env.EDGE_CONFIG,
    PURPOSITY_CANARY_DEPLOYMENT: process.env.PURPOSITY_CANARY_DEPLOYMENT,
    VERCEL_ENV: process.env.VERCEL_ENV,
    NEXT_PUBLIC_CDN_ORIGIN: process.env.NEXT_PUBLIC_CDN_ORIGIN,
    NEXT_PUBLIC_API_ORIGIN: process.env.NEXT_PUBLIC_API_ORIGIN,
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
    NEXT_PUBLIC_CLERK_SIGN_IN_URL: '/login',
    NEXT_PUBLIC_CLERK_SIGN_UP_URL: `https://gateway.purposity.app/signup?project=www`,
    NEXT_PUBLIC_CLERK_AFTER_SIGN_IN_URL: '/home',
    NEXT_PUBLIC_CLERK_AFTER_SIGN_UP_URL: 'https://gateway.purposity.app/home?project=www',
    NEXT_PUBLIC_PURPOSITY_CANARY_DEPLOYMENT: process.env.NEXT_PUBLIC_PURPOSITY_CANARY_DEPLOYMENT,
    NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  },
})
