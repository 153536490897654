var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring-tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"MTpZ9rgs1sXHj3Uk7OvL9"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations'
import {
  init,
  showReportDialog,
  BrowserOptions,
  Replay,
  BrowserTracing,
  addGlobalEventProcessor,
} from '@sentry/nextjs'
import { PURPOSITY_ENV, SENTRY_DSN } from './constants.mjs'
import { isCheckly, ZodProcessor } from './sentry.common'

init({
  dsn: SENTRY_DSN,
  environment: PURPOSITY_ENV,

  beforeSend(event, hint) {
    if (isCheckly(event)) return null
    if (event.exception) {
      if (PURPOSITY_ENV === 'development') {
        console.error(event)
      } else {
        showReportDialog({ eventId: event.event_id })
      }
    }
    return event
  },

  tracesSampleRate: 1.0,

  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: PURPOSITY_ENV === 'preview' ? 1 : 0.1,

  integrations: [
    new ExtraErrorDataIntegration(),
    new BrowserTracing({
      tracePropagationTargets: [
        /^\//,
        '/localhost/',
        /^https:\/\/(?!clerk\.)([a-zA-Z0-9-.]+\.)?purposity\.com/,
        /purposity\.app/,
        /hasura\.app/,
      ],
      heartbeatInterval: 10000,

      shouldCreateSpanForRequest(href) {
        const url = new URL(href)
        /** we independently create GraphQL Transactions */
        return (
          url.pathname !== '/v1/graphql' && url.pathname !== '/v1beta1/relay'
        )
      },
    }),

    new Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  beforeSendTransaction(event, hint) {
    if (isCheckly(event)) return null
    return event
  },
} satisfies BrowserOptions)

addGlobalEventProcessor(ZodProcessor)
