import * as React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { ClerkProvider } from '@clerk/nextjs'
import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { NotificationsProvider } from '@mantine/notifications'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import localFont from 'next/font/local'
import { ClerkUpdater } from '@components/clerk-updater'
import { ProductionReactQueryDevtools } from '@components/devtools.react-query'
import { PURPOSITY_ENV } from '~constants.mjs'
import { env } from '~env.mjs'

const sofiaBold = localFont({ src: '../assets/fonts/SofiaPro-Bold.otf' })
global.PURPOSITY_ENV = PURPOSITY_ENV

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

export type AppPropsWithLayout<P = object> = AppProps<P> & {
  Component: NextPageWithLayout<P>
}
export default function App(props: AppPropsWithLayout) {
  const { Component, pageProps } = props
  const getLayout = Component.getLayout ?? ((page) => page)

  const [purposityEnv] = React.useState(PURPOSITY_ENV)

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus() {
              return PURPOSITY_ENV !== 'development'
            },
          },
        },
      })
  )

  return (
    <>
      <Head>
        <title>Admin Panel — Purposity</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />

        <meta name="PURPOSITY_ENV" content={purposityEnv} />
      </Head>
      <ClerkProvider
        publishableKey={env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY}
        signInUrl={env.NEXT_PUBLIC_CLERK_SIGN_IN_URL}
        signUpUrl={env.NEXT_PUBLIC_CLERK_SIGN_UP_URL}
        afterSignInUrl={env.NEXT_PUBLIC_CLERK_AFTER_SIGN_IN_URL}
        afterSignUpUrl={env.NEXT_PUBLIC_CLERK_AFTER_SIGN_UP_URL}
        navigate={(to) => props.router.push(to)}
        {...pageProps}
      >
        <QueryClientProvider client={queryClient}>
          {PURPOSITY_ENV === 'development' ? (
            <ReactQueryDevtools data-sentry-block />
          ) : (
            <ProductionReactQueryDevtools data-sentry-block />
          )}
          <ClerkUpdater />
          <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
              colors: {
                'purposity-blue': [
                  '#D3E7ED',
                  '#B1DAE6',
                  '#8CD1E6',
                  '#63CDEE',
                  '#33CFFF',
                  '#2CBBE8',
                  '#2DA6CB',
                  '#368EA9',
                  '#3B7A8E',
                  '#3D6A78',
                ],
              },
              primaryShade: 5,
              primaryColor: 'purposity-blue',
              fontFamily: 'SofiaPro-Bold, sans-serif',
              colorScheme: 'light',
              globalStyles: (/*theme*/) => ({
                body: {
                  margin: 0,
                  padding: 0,
                  fontWeight: 500,
                  WebkitFontSmoothing: 'antialiased',
                  MozOsxFontSmoothing: 'grayscale',
                  height: '100vh',
                  width: '100vw',
                  display: 'flex',
                },
                '#__next': {
                  display: 'flex',
                  flex: '1 1 100vw',
                },
              }),
              components: {
                Text: {
                  classNames: { root: sofiaBold.className },
                },
              },
            }}
          >
            <NotificationsProvider position="top-center">
              <ModalsProvider>
                {getLayout(<Component {...pageProps} />)}
              </ModalsProvider>
            </NotificationsProvider>
          </MantineProvider>
        </QueryClientProvider>
      </ClerkProvider>
    </>
  )
}
