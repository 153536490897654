// must be JS, consumed by next.config.mjs
/* eslint-disable no-process-env */

import packageJson from './package.json'

const _isPreviewDep =
  process.env.VERCEL_ENV === 'preview' ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
const _isCanaryDep =
  _isPreviewDep &&
  (process.env.PURPOSITY_CANARY_DEPLOYMENT === '1' ||
    process.env.NEXT_PUBLIC_PURPOSITY_CANARY_DEPLOYMENT === '1')

const _isProductionDep =
  process.env.VERCEL_ENV === 'production' ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
const _isLocalDep = process.env.NODE_ENV === 'production' && !_isProductionDep

const _isDevelopmentDep =
  !_isProductionDep && !_isCanaryDep && !_isPreviewDep && !_isLocalDep

export const PURPOSITY_ENV = _isProductionDep
  ? 'production'
  : _isCanaryDep
  ? 'canary'
  : _isPreviewDep
  ? 'preview'
  : _isLocalDep
  ? 'local'
  : _isDevelopmentDep
  ? 'development'
  : 'unknown'

export const VERSION = packageJson.version

export const SENTRY_DSN =
  'https://050add50d84440e7b18de6391e6b778f@o546790.ingest.sentry.io/4504889182126080'
