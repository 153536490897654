import React, { useEffect } from 'react'
import { useUser } from '@clerk/nextjs'
import { setUser } from '@sentry/nextjs'

export function useUpdater() {
  const { isLoaded, isSignedIn, user } = useUser()

  useEffect(() => {
    if (isLoaded) {
      if (isSignedIn) {
        setUser({
          id: user.id,
          name: user.fullName || undefined,
          email: user.primaryEmailAddress?.emailAddress,
          segment:
            user.organizationMemberships?.[0]?.organization?.name || undefined,
        })
      } else {
        setUser(null)
      }
    }
    return () => {
      setUser(null)
    }
  }, [
    isLoaded,
    isSignedIn,
    user?.id,
    user?.primaryEmailAddress?.emailAddress,
    user?.fullName,
    user?.organizationMemberships?.[0]?.organization?.name,
  ])
}

export function ClerkUpdater() {
  useUpdater()
  return null
}
