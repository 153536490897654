import * as z from 'zod'
import type {
  ErrorEvent,
  TransactionEvent,
  EventProcessor,
} from '@sentry/types'

export const isCheckly = (event: ErrorEvent | TransactionEvent) =>
  event.request?.headers?.['User-Agent']?.match?.(/checkly/i)

export const ZodProcessor: EventProcessor = function (event, hint) {
  /** Format ZodErrors for Sentry */
  if (hint.originalException instanceof z.ZodError) {
    return {
      ...event,
      contexts: {
        ...event.contexts,
        zod: {
          formErrors: hint.originalException.formErrors,
          format: hint.originalException.format(),
          issues: hint.originalException.issues,
        },
      },
      extra: {
        ...event.extra,
        'validation-issues': hint.originalException.format(),
      },
    }
  }

  return event
}
